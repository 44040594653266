<template>
  <div class="text-left LoginNew" style="overflow-x: hidden">
    <div v-if="isLoading">
      <LoaderComp />
    </div>
  </div>
</template>

<script>
import LoaderComp from "../customize/component/LoaderComp.vue";
export default {
  components: {
    LoaderComp,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  mounted() {
    let obj = {
        type:"integration",
        code: this.$route.query.code
    }
    window.opener.postMessage(obj, "*");
    window.close()
  },
};
</script>

<style scoped>
.LoginNew {
  height: 100vh;
}
.LoginNew .MainSection {
  padding: 2% 5%;
  background: #ffffff;
}
</style>
